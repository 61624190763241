import { withUsersGlobalProps } from '@/users/application/withUsersGlobalProps';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import Layout from '@/users/ui/common/Layout';
import LoginComponent from '@/users/ui/components/Login';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';
import { OTelInstrumentation } from '@/Framework/OTel/OTelInstrumentation';

export const getServerSideProps = withUsersGlobalProps(() => ({ props: {} }));

const Login = ({ tenantTheme }: { tenantTheme: ITenantTheme }) => (
  <OTelInstrumentation name={ 'LoginLayout' }>
    <Layout
      withBanner
      onInit={ useSessionContext().switchToPublicAccess }
      tenantTheme={ tenantTheme }
    >
      <OTelInstrumentation name={ 'LoginComponent' }>
        <LoginComponent />
      </OTelInstrumentation>
    </Layout>
  </OTelInstrumentation>
);

export default Login;
