import React from 'react';
import { RetainQueryLink } from '@/Framework/Router/Next/Link';

function NeedAnAccount({ className }: { className?: string }) {
  return (
    <span className={ className }>
      Need an account? <RetainQueryLink href="/register" dataTest="registerLink">Register Now</RetainQueryLink>
    </span>
  );
}

export default NeedAnAccount;
